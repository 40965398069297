import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { ArrowDownward, Note, ShoppingCart } from "@mui/icons-material";
import Overview from "./TabData/Overview";
import Devices from "./TabData/Devices";
import Checkout from "./TabData/Checkout";
import InfraNotes from "./TabData/InfraNotes";
import InternalNotes from "./TabData/InternalNotes";
import { checkUserDetailsFromCookie } from "../../../api_calls/userManagement";

// to load tab content
function CustomTabPanel(props) {
  const { children, dasboardname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${dasboardname}`}
      aria-labelledby={`simple-tab-${dasboardname}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  dasboardname: PropTypes.number.isRequired,
};

// configure location tabs detail >> label, icon, dashboard and filter
export const fetchInfraTabsInfo = (userType) => {
  const userTypeInfo = checkUserDetailsFromCookie();
  let userDetailObj = {};
  if (userTypeInfo.success === true) {
    userDetailObj = userTypeInfo.user_detail;
  }

  let infraTabs = [
    {
      label: "Overview",
      // icon: <Visibility />,
      unique_key: "general_1",
      dashboard: <Overview />,
      filter_component: <></>,
    },
    {
      label: "Devices",
      // icon: <Devices />,
      unique_key: "devices_1",
      dashboard: <Devices />,
      filter_component: <></>,
    },
    {
      label: "Checkout",
      icon: <ShoppingCart />,
      unique_key: "checkoutMapping_1",
      dashboard: <Checkout />,
      filter_component: <></>,
    },
    {
      label: "Notes",
      icon: <Note />,
      unique_key: "note_infrastructure_1",
      dashboard: <InfraNotes />,
      filter_component: <></>,
    },
  ];

  if (typeof userDetailObj.user_type !== "undefined" && userDetailObj.user_type === "cyreen") {
    infraTabs = [
      ...infraTabs,
      {
        label: "Internal Notes",
        icon: <Note />,
        unique_key: "internalnote_infrastructure_1",
        dashboard: <InternalNotes />,
        filter_component: <></>,
      },
    ];
  }

  return infraTabs;
};
