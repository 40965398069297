import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import {
  HtmlTooltip,
  IconContainer,
  IconText,
  StyledMainBox,
  StyledStack,
  StyledSwitch,
} from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

import { Add, Close, Delete, Download, Edit, Save, Store, Update } from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import Badge from "@mui/material/Badge";
// import DeleteActivity from "../modals/DeleteActivityConfirmation";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import * as xlsx from "xlsx";
import AddNewMarketingActivity from "../ModalContent/AddMarketingActivity";
import CustomBadge from "../../../components/commonComponents/Badge/CustomBadge";
import ConfirmationPopup from "../../../components/commonComponents/ConfirmationPopup";
import DeleteActivity from "../ModalContent/DeleteActivityConfirmation";

const MarketingActivitiesList = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const sheetDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [newActivity, setNewActivity] = React.useState(false);
  const [deleteActivity, setDeleteActivityModal] = React.useState(false);
  const t = useCustomTranslation();

  const id = campaignRecord.campaign_id;

  const dataForDeletion = (activityRecord) => {
    if (typeof activityRecord !== "undefined" && Object.keys(activityRecord).length !== 0) {
      dispatchAction(
        updateStateData({
          activity_ids_to_delete: activityRecord.id_marketing_activity.split(",").map((item) => parseInt(item)),
        })
      );
    }
  };

  const dataForAutoFilling = (activityRecord) => {
    if (typeof activityRecord !== "undefined" && Object.keys(activityRecord).length !== 0) {
      let weekSelections = [];
      let storeSelections = [];
      let gtinSelection = [];
      let activitySelection = {
        value: activityRecord.activity_type,
        label: activityRecord.name_activity,
      };
      //week
      if (activityRecord.week.length !== 0) {
        activityRecord.week.forEach((week) => {
          weekSelections.push({ value: parseInt(week), label: parseInt(week) });
        });
      }
      // stores
      if (activityRecord.id_store.length !== 0) {
        activityRecord.id_store.forEach((storeId, index) => {
          storeSelections.push({ id: parseInt(storeId), label: activityRecord.store_names[index] });
        });
      }
      // GTIN
      if (activityRecord.gtin.length !== 0) {
        activityRecord.gtin.forEach((gtin, index) => {
          gtinSelection.push({ value: parseInt(gtin), label: activityRecord.description[index] });
        });
      }

      dispatchAction(
        updateStateData({
          activity_ids_to_update: activityRecord.id_marketing_activity.split(",").map((item) => parseInt(item)),
          activity_to_update: {
            week_selection: weekSelections,
            storeSelection: storeSelections,
            activitySelection: activitySelection,
            discounted_price: activityRecord.discounted_price,
            original_price: activityRecord.original_price,
            discount_range: activityRecord.discount_range,
            gtinSelections: gtinSelection,
          },
          activity_selections: {
            campaign_id: activityRecord.id_campaign,
            company_id: activityRecord.id_company,
            year: activityRecord.year,
            activity_name: activityRecord.name_activity,
            activity_type: activityRecord.activity_type,
            discounted_price: activityRecord.discounted_price,
            original_price: activityRecord.original_price,
            discounted_range: activityRecord.discount_range,
            weeks: activityRecord.week,
            gtin: activityRecord.gtin.map((gtinVal, index) => {
              return {
                description: activityRecord.description[index],
                gtin: parseInt(gtinVal),
              };
            }),
            stores: activityRecord.id_store.map((storeId) => {
              return {
                store_id: parseInt(storeId),
                id_region: sheetDetails.stores_detail[storeId].id_region,
                market_type: sheetDetails.stores_detail[storeId].market_type,
              };
            }),
          },
        })
      );
    }

    setNewActivity(true);
  };

  const exportToExcel = () => {
    // Convert JSON data to an array of arrays
    const data = [];

    data.push([
      t("week_text"),
      "GTIN",
      t("description_text"),
      t("stores_text"),
      t("activity_text"),
      t("discount_price_text"),
      t("original_range_text"),
    ]); // Add headers

    sheetDetails.activities_data
      .slice()
      .sort((next, prev) => {
        return next.week - prev.week;
      })
      .forEach((item) => {
        item.store_names.forEach((storeName) => {
          const row = [];
          row.push(
            item.week[0],
            item.gtin[0],
            item.description[0],
            storeName,
            item.name_activity,
            item.discounted_price,
            item.original_price
          );
          data.push(row);
        });
      });

    // Create a new workbook and add a worksheet
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    let exportFileName =
      typeof sheetDetails.campaign_detail !== "undefined"
        ? "export_" + sheetDetails.campaign_detail.campaign_name + "_marketing_activities.xlsx"
        : "marketingActivities.xlsx";

    // Save the workbook to an Excel file
    xlsx.writeFile(workbook, exportFileName);
  };

  React.useEffect(() => {
    // if (typeof sheetDetails.campaign_detail !== "undefined") {
    //   fetchStores(sheetDetails.campaign_detail.retailer_id).then((stores) => {
    //     fetchMarketingActivities(id);
    //   });
    // }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => {
          e.preventDefault();
        }}
        fullWidth={true}
        maxWidth={size}
      >
        <DialogTitle>{t("marketing_activity_heading_text")}</DialogTitle>
        <DialogContent>
          <StyledMainBox component="main">
            <Box height={20} />
            {![2, 4].includes(campaignRecord.campaign_status_code) && (
              <>
                <Box>
                  <Stack direction={"row"} spacing={4}>
                    <ActionButton
                      onClick={(e) => {
                        e.preventDefault();
                        setNewActivity(true);
                      }}
                      label={"Add New Activity"}
                      icon={<Add />}
                    />
                  </Stack>
                </Box>
                <Box height={20} />
              </>
            )}

            {/* --------- show campaign sheet content along with checkboxes --------- */}
            <Stack direction={"row"} spacing={4}>
              {typeof sheetDetails.activities_data !== "undefined" && sheetDetails.activities_data.length !== 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="marketing activities loaded successfully">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("week_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          GTIN
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("description_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("stores_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("activity_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("discount_price_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          {t("original_range_text")}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sheetDetails.activities_data
                        .slice()
                        .sort((next, prev) => {
                          return next.week - prev.week;
                        })
                        .map((activity) => {
                          return (
                            <TableRow key={activity.id_marketing_activity + "_activity"}>
                              {/* ---------------- Weeks ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"column"} spacing={1}>
                                  {activity.week.map((weekNum) => {
                                    return <CustomBadge key={weekNum + "_week"} label={weekNum} />;
                                  })}
                                </StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Gtin ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"row"}>
                                  {activity.gtin.map((gtinVal) => {
                                    return gtinVal;
                                  })}
                                </StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Description ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"row"}>{activity.description}</StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Stores ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"column"} spacing={1}>
                                  {/* {activity.store_names.join(",")} */}
                                  <HtmlTooltip title={activity.store_names.join(", ")}>
                                    <IconContainer>
                                      <Store sx={{ color: "#AF3241" }} />
                                      <IconText variant="body2"> {activity.store_names.length}</IconText>
                                    </IconContainer>
                                  </HtmlTooltip>
                                  {/* {activity.store_names.map((storeName) => {
                                    return <CustomBadge key={storeName + "_store"} label={storeName} />;
                                  })} */}
                                </StyledStack>
                              </StyledTableCell>
                              {/* ---------------- activity name ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"row"}>{activity.name_activity}</StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Discount price ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"row"}>{activity.discounted_price}</StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Original price ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                <StyledStack direction={"row"}>{activity.original_price}</StyledStack>
                              </StyledTableCell>
                              {/* ---------------- Actions ---------- */}
                              <StyledTableCell component="td" scope="row" className="text-wrapper">
                                {![2, 4].includes(campaignRecord.campaign_status_code) && (
                                  <StyledStack direction={"row"}>
                                    {/* -------- update activity -------- */}
                                    {/* <HtmlTooltip title={"Edit"}>
                                      <IconContainer>
                                        <a
                                          href="#editActivity"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dataForAutoFilling(activity);
                                          }}
                                        >
                                          <Edit sx={{ color: "#AF3241" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip> */}
                                    {/* ---------- delete activity ---------- */}
                                    <HtmlTooltip title={"Delete"}>
                                      <IconContainer>
                                        <a
                                          href="#deleteActivity"
                                          aria-label="delete activity"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dataForDeletion(activity);
                                            setDeleteActivityModal(true);
                                          }}
                                        >
                                          <Delete sx={{ color: "#AF3241" }} />
                                        </a>
                                      </IconContainer>
                                    </HtmlTooltip>
                                  </StyledStack>
                                )}
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="row mb-3">
                  <div className="col-md-12">No marketing activities added</div>
                </div>
              )}
            </Stack>
            <Box height={40} />
            <StyledStack direction={"row"} spacing={4}>
              {/* ------ export to excel button ------- */}
              {typeof sheetDetails.activities_data !== "undefined" && sheetDetails.activities_data.length !== 0 && (
                <ActionButton
                  onClick={(e) => {
                    e.preventDefault();
                    exportToExcel();
                  }}
                  label={t("download_activity_button_text")}
                  icon={<Download />}
                />
              )}
            </StyledStack>
          </StyledMainBox>
        </DialogContent>
        <Box height={20} />
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <CloseButton
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
              label="Cancel"
              icon={<Close />}
            />
          </Stack>
        </DialogActions>
      </Dialog>

      {/* ---------- new marketing activity popup ---------- */}
      {newActivity === true && (
        <AddNewMarketingActivity
          open={newActivity}
          onClose={(e) => {
            e.preventDefault();
          }}
          size={"xl"}
          closeModal={() => {
            setNewActivity(false);
          }}
          campaignRecord={campaignRecord}
          usedFor={"add"}
        />
      )}

      {/* --------------- Delete activity modal ----------- */}
      {deleteActivity === true && (
        <DeleteActivity showdeleteActivityPopup={deleteActivity} closePopup={setDeleteActivityModal} />
      )}
    </>
  );
};

export default MarketingActivitiesList;
