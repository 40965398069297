import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { sendApiRequest, sendApiRequestWithFile } from "./requestConfig";
import {
  allNumbersBetweenRange,
  formatToStandardDate,
  validateAlphabets,
  validateAlphanumeric,
  validateEmpty,
  validateNegativeDecimalNumbers,
  validateNumbers,
} from "./utils";

export const uploadDataFiles = async (selectedFiles) => {
  const formData = new FormData();
  selectedFiles.forEach((fileDetail) => {
    formData.append("files", fileDetail);
  });
  //   formData.append("files", selectedFiles);
  const saveDataFiles = await sendApiRequestWithFile("data-exchange/upload", formData, "POST");
  if (typeof saveDataFiles.success !== "undefined" && saveDataFiles.success === true) {
    viewExistingUploads();
    toast("File(s) uploaded successfully", { type: "success" });
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};

export const viewExistingUploads = async () => {
  const sendRequest = await sendApiRequest("data-exchange/view/in", {}, "GET");
  if (typeof sendRequest.success !== "undefined" && sendRequest.success === true) {
    let filesList = sendRequest.response;
    // save to the redux state
    store.dispatch(
      updateStateData({
        existing_file_upload_list: filesList.slice().sort((a, b) => {
          return formatToStandardDate(b.lastModified) - formatToStandardDate(a.lastModified);
        }),
      })
    );
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};

export const viewCyreenUploads = async () => {
  const sendRequest = await sendApiRequest("data-exchange/view/out", {}, "GET");
  if (typeof sendRequest.success !== "undefined" && sendRequest.success === true) {
    let filesList = sendRequest.response;
    // save to the redux state
    store.dispatch(
      updateStateData({
        cyreen_file_upload_list: filesList.slice().sort((a, b) => {
          return formatToStandardDate(a.lastModified) - formatToStandardDate(b.lastModified);
        }),
      })
    );
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};

export const downloadUploadedFile = async (fileName) => {
  const downloadUrl = await sendApiRequest(
    "data-exchange/download/in",
    {
      name: fileName,
    },
    "POST"
  );
  if (typeof downloadUrl.success !== "undefined" && downloadUrl.success === true) {
    if (typeof downloadUrl.response.error_detail !== "undefined" && downloadUrl.response.error_detail !== "") {
      toast(downloadUrl.response.error_detail, { type: "error" });
    } else {
      window.location = downloadUrl.response;
    }
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};

export const downloadCyreenFile = async (fileName) => {
  const downloadUrl = await sendApiRequest(
    "data-exchange/download/out",
    {
      name: fileName,
    },
    "POST"
  );
  if (typeof downloadUrl.success !== "undefined" && downloadUrl.success === true) {
    if (typeof downloadUrl.response.error_detail !== "undefined" && downloadUrl.response.error_detail !== "") {
      toast(downloadUrl.response.error_detail, { type: "error" });
    } else {
      window.location = downloadUrl.response;
    }
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};

export const sortUploadedFilesList = (sortingParamName, sortingParamVal) => {
  const userStateData = store.getState().userSelections;
  const sortedList =
    typeof userStateData.existing_file_upload_list !== "undefined" && userStateData.existing_file_upload_list.length !== 0
      ? userStateData.existing_file_upload_list.slice().sort((a, b) => {
          if (sortingParamName === "fileSize") {
            return sortingParamVal === "asc"
              ? parseFloat(a.size) - parseFloat(b.size)
              : parseFloat(b.size) - parseFloat(a.size);
          } else if (sortingParamName === "fileName") {
            return sortingParamVal === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
          } else {
            return sortingParamVal === "asc"
              ? formatToStandardDate(a.lastModified) - formatToStandardDate(b.lastModified)
              : formatToStandardDate(b.lastModified) - formatToStandardDate(a.lastModified);
          }
        })
      : [];

  store.dispatch(
    updateStateData({
      ...userStateData,
      existing_file_upload_list: sortedList,
    })
  );
};

export const sortCyreenFilesList = (sortingParamName, sortingParamVal) => {
  const userStateData = store.getState().userSelections;
  const sortedList =
    typeof userStateData.cyreen_file_upload_list !== "undefined" && userStateData.cyreen_file_upload_list.length !== 0
      ? userStateData.cyreen_file_upload_list.slice().sort((a, b) => {
          if (sortingParamName === "fileSize") {
            return sortingParamVal === "asc"
              ? parseFloat(a.size) - parseFloat(b.size)
              : parseFloat(b.size) - parseFloat(a.size);
          } else if (sortingParamName === "fileName") {
            return sortingParamVal === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
          } else {
            return sortingParamVal === "asc"
              ? formatToStandardDate(a.lastModified) - formatToStandardDate(b.lastModified)
              : formatToStandardDate(b.lastModified) - formatToStandardDate(a.lastModified);
          }
        })
      : [];

  store.dispatch(
    updateStateData({
      ...userStateData,
      cyreen_file_upload_list: sortedList,
    })
  );
};

export const deleteUploadedFile = async (fileName) => {
  const deleteFile = await sendApiRequest(
    "data-exchange/delete",
    {
      name: fileName,
    },
    "POST"
  );
  if (typeof deleteFile.success !== "undefined" && deleteFile.success === true) {
    if (typeof deleteFile.response.error_detail !== "undefined" && deleteFile.response.error_detail !== "") {
      toast(deleteFile.response.error_detail, { type: "error" });
    } else {
      // reload the list
      viewExistingUploads();
      toast("File Deleted Successfully.", { type: "success" });
    }
  } else {
    toast("Sorry, something went wrong", { type: "error" });
  }
};
