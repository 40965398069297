// Global Variables
let viz, workbook, activeSheet;
let TableauEventType; // Declare the Tableau constants as global variables

const FilterUpdateType = {
  Replace: "replace",
  Add: "add",
  Remove: "remove",
};

// 1 - Create a View
export function initializeViz(token, visualSrc, vizId) {
  viz = document.getElementById(vizId);
  viz.hideTabs = true;
  viz.hideToolbar = true;
  viz.token = token;

  viz.addEventListener("load", () => {
    const onFirstInteractive = () => {
      workbook = viz.workbook;
      activeSheet = workbook.activeSheet;
    };

    // Use the global TableauEventType here
    viz.addEventListener(TableauEventType.FirstInteractive, onFirstInteractive);
  });

  viz.src = visualSrc;
}

// Set up periodic refresh
export const refreshData = (vizId) => {
  viz = document.getElementById(vizId);
  if (viz) {
    viz.refreshDataAsync().catch((err) => {
      console.error("Error during refresh:", err);
    });
  }
};

// 2 - Filter
// "id Store (Slave)", ["20"]
export async function filterSingleValue(filterName, filterValue, vizId) {
  viz = document.getElementById(vizId);
  workbook = viz.workbook;
  activeSheet = workbook.activeSheet;

  activeSheet.worksheets.forEach(async (sheet) => {
    await sheet.applyFilterAsync(filterName, filterValue, FilterUpdateType.Replace);
  });
}

// Change parameters dynamically
export async function changeParameter(parameterName, newValue, vizId) {
  viz = document.getElementById(vizId);
  workbook = viz.workbook;
  activeSheet = workbook.activeSheet;
  // parameter updation can only be done for workbook.
  await workbook.changeParameterValueAsync(parameterName, newValue);
}

export async function filterRangeValue(filterName, filterValue, vizId) {
  viz = document.getElementById(vizId);
  workbook = viz.workbook;
  activeSheet = workbook.activeSheet;

  activeSheet.worksheets.forEach(async (sheet) => {
    // await sheet.applyFilterAsync(filterName, filterValue, FilterUpdateType.Add);

    await sheet.applyRangeFilterAsync(
      filterName,
      {
        min: filterValue[0],
        max: filterValue[1],
      },
      FilterUpdateType.Replace
    );
  });
}

// to filter multiple values
export async function addValuesToFilter(filterName, FilterValues, vizId) {
  viz = document.getElementById(vizId);
  workbook = viz.workbook;
  activeSheet = workbook.activeSheet;
  await activeSheet.applyFilterAsync(filterName, FilterValues, FilterUpdateType.Add);
}

// to check what filters are applied
export const checkWorkbookFilters = async (vizId) => {
  viz = document.getElementById(vizId);
  workbook = viz.workbook;
  activeSheet = workbook.activeSheet;

  // Iterate through each sheet to get applied filters
  let filtersToApply = await activeSheet.getFiltersAsync().then((sheetFilters) => {
    // Extract filter details
    return sheetFilters;
  });
};
