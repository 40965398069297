import React from "react";
import { filterSingleValue } from "../../../../api_calls/TableauVisuals";

import { loadStores } from "../../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { findStoreIds } from "../../../../api_calls/utils";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";
import {
  StickyTopRowStack,
  StyledBlackInformationTypography,
} from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import { updateStateData } from "../../../../Store/actions";

const GraffanaFilter = ({ vizImpressionId, vizCheckoutId, fieldName }) => {
  const storeDetails = useSelector((state) => state.userSelections);
  const [storeNameOptionSelected, setStoreNameOptionsSelected] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const dispatchAction = useDispatch();

  const [defaultValuesLoaded, setDefaultValuesLoaded] = React.useState(true);

  let storeList = [];
  storeList =
    typeof storeDetails.stores_list !== "undefined" && storeDetails.stores_list.length !== 0
      ? [
          // ...["(All)"],
          ...storeDetails.stores_list.map((storeInfo) => {
            return storeInfo.name;
          }),
        ].sort()
      : [];

  React.useEffect(() => {
    const fetchData = async () => {
      await loadStores();
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (dataLoaded) {
      // setStoreSelection(storeList[0]);
      setStoreNameOptionsSelected({ value: storeList[0], label: storeList[0] });
      // setTimeout(() => {
      //   setDefaultValuesLoaded(true);
      // }, 1000);
    }
  }, [dataLoaded]);

  return defaultValuesLoaded ? (
    <StickyTopRowStack direction={"row"} spacing={4}>
      <Box sx={{ width: 220 }}>
        <StyledBlackInformationTypography>Refresh rate: 30 seconds</StyledBlackInformationTypography>
      </Box>
      <Box sx={{ width: 220 }}>
        <SingleSelect
          enableFullWidth={true}
          label={"Store"}
          selectionCheck={(option, value) => option.label === value.label}
          options={[...new Set(storeList)].map((storeName) => {
            return { value: storeName, label: storeName };
          })}
          defaultVal={storeNameOptionSelected}
          onChangeCall={(selectedOption) => {
            setStoreNameOptionsSelected(selectedOption);
            const StoreIds = findStoreIds(selectedOption.value);
            dispatchAction(
              updateStateData({
                live_monitoring_selected_store: selectedOption.value,
              })
            );
            filterSingleValue("Id Store", StoreIds, vizImpressionId);
            filterSingleValue("Id Store", StoreIds, vizCheckoutId);
          }}
        />
      </Box>
    </StickyTopRowStack>
  ) : (
    <></>
  );
};

export default GraffanaFilter;
