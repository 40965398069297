import React, { useState } from "react";
import { StyledMainBox, StyledNestedTabs } from "../../../components/commonComponents/StyledComponents/styled";
import { Grid, Select, MenuItem, FormControl, InputLabel, Box, Tab } from "@mui/material";
import { useSelector } from "react-redux";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import { refreshData } from "../../../api_calls/TableauVisuals";
import PropTypes from "prop-types";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nested-tabpanel-${index}`}
      aria-labelledby={`nested-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `checkout-tab-${index}`,
    "aria-controls": `checkout-tabpanel-${index}`,
  };
}

const GraffanaDash = ({
  vizImpressionId,
  vizCheckoutId,
  vizImpressionUrl,
  vizCheckoutUrl,
  applyFilter,
  filterConfig,
  refreshInterval,
}) => {
  const userStateDetails = useSelector((state) => state.userSelections);
  const [nestedValue, setNestedValue] = React.useState(0);
  const [loadCheckoutDash, setLoadCheckoutDash] = React.useState(false);
  let tabs = [
    {
      label: "Impressions",
      unique_key: "impressions_live_dash",
      dashboard: (
        <Box>
          <TableauDash
            vizId={vizImpressionId}
            vizUrl={vizImpressionUrl}
            applyFilter={applyFilter}
            filterConfig={filterConfig}
            autoRefresh={true}
          />
        </Box>
      ),
      filter_component: <></>,
    },
    {
      label: "Checkout",
      unique_key: "checkout_live_dash",
      dashboard: (
        <Box>
          <TableauDash
            vizId={vizCheckoutId}
            vizUrl={vizCheckoutUrl}
            applyFilter={applyFilter}
            filterConfig={filterConfig}
            autoRefresh={true}
          />
        </Box>
      ),
      filter_component: <></>,
    },
  ];

  const handleChange = (event, newValue) => {
    setNestedValue(newValue);
  };

  React.useEffect(() => {
    // setTimeout(() => {
    //   setLoadCheckoutDash(true);
    // }, 2000);

    const intervalId = setInterval(() => {
      if (typeof userStateDetails.monitoring_active_tab !== "undefined" && userStateDetails.monitoring_active_tab === 1) {
        console.log("data refresh called", new Date());
        refreshData(vizImpressionId);
        refreshData(vizCheckoutId);
      }
    }, refreshInterval);
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshInterval]);
  return (
    <>
      {/* <Box height={5} /> */}
      <StyledNestedTabs value={nestedValue} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
        {tabs.map((tabDetail) => {
          return <Tab key={tabDetail.unique_key} label={tabDetail.label} {...a11yProps(0)} />;
        })}
      </StyledNestedTabs>

      {/* ============== tab contents =========== */}
      {tabs.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={nestedValue} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}
    </>
  );
};

export default GraffanaDash;
