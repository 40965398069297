import React, { useRef } from "react";
import { Box, Button, Stack } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { HtmlTooltip, IconContainer, StyledMainBox } from "../../../../components/commonComponents/StyledComponents/styled";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import CloseButton from "../../../../components/commonComponents/Buttons/CloseButton";
import ActionButton, { DarkGreyButton } from "../../../../components/commonComponents/Buttons/ActionButton";
import { Close, Download, Info, Save } from "@mui/icons-material";
import * as quillToWord from "quill-to-word";
import { saveInfraNote } from "../../../../api_calls/infrastructure";
import { useParams } from "react-router-dom";

const InfraNotes = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [value, setValue] = React.useState(userStateData?.infra_note);
  const [fieldError, setFieldError] = React.useState({});
  const quillRef = useRef(null);
  const { id } = useParams();

  const handleExportDocx = async () => {
    const delta = quillRef.current.getEditor().getContents();
    const quillToWordConfig = {
      exportAs: "blob",
    };
    const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig);
    saveAs(docAsBlob, "storeNote.docx");
  };

  const saveNoteText = () => {
    const noteText = value;
    setFieldError({});
    if (noteText === "") {
      setFieldError({
        note_text: "Please add a note",
      });
    } else {
      setFieldError({});
      let noteId = typeof userStateData.note_id !== "undefined" ? userStateData.note_id : "";
      saveInfraNote(noteText, id, noteId, "normal");
    }
  };

  React.useEffect(() => {
    if (typeof userStateData.infra_note !== "undefined") {
      setValue(userStateData.infra_note);
    }
  }, []);

  return (
    <StyledMainBox component="main">
      <Box>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [{ list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
              ["clean"],
            ],
          }}
        />
        {typeof fieldError.note_text !== "undefined" && (
          <HtmlTooltip title={fieldError.note_text}>
            <IconContainer>
              <Info sx={{ color: "#AF3241" }} />
            </IconContainer>
          </HtmlTooltip>
        )}
      </Box>
      {/* --------- action button ---------- */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
            }}
            label="Cancel"
            icon={<Close />}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <DarkGreyButton
            onClick={(e) => {
              e.preventDefault();
              handleExportDocx();
            }}
            label="Export Note"
            icon={<Download />}
          />
          <Box ml={2}>
            {/* Add margin between the buttons */}
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                saveNoteText();
              }}
              label="Save Note"
              icon={<Save />}
            />
          </Box>
        </Box>
      </Box>
    </StyledMainBox>
  );
};

export default InfraNotes;
