import * as React from "react";
import { Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PatternImage from "./image/loginPatternImage.svg";
import { LogoImage } from "../../components/commonComponents/StyledComponents/styled";
import WhiteLogo from "../../images/cyreen_logo_white_sRGB _1200.png";
import { authorizeCredentials, deleteCookie, logout } from "../../api_calls/requestConfig";
import { useNavigate } from "react-router-dom";
import { DarkGreyButton } from "../../components/commonComponents/Buttons/ActionButton";
import { LogInButton } from "../../components/commonComponents/Buttons/ActionButton";
import { Login } from "@mui/icons-material";
import { toast } from "react-toastify";
import WhiteInputField, { WhiteInputPasswordField } from "../../components/commonComponents/TextInputs/WhiteTextInput";
import WorkSpace from "./modalContent/WorkSpace";
import { useSelector } from "react-redux";

function Copyright(props) {
  return (
    <Typography variant="body2" color="#fff" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://cyreen.de/">
        Cyreen
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSide() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = React.useState({});
  const currentStateData = useSelector((state) => state.userSelections);
  const Authenticate = (e) => {
    e.preventDefault();
    if (
      typeof loginData.username !== "undefined" &&
      loginData.username !== "" &&
      typeof loginData.password !== "undefined" &&
      loginData.password !== ""
    ) {
      authorizeCredentials(loginData, navigate);
    } else {
      toast("Please enter email and password", { type: "error" });
    }
  };

  React.useEffect(() => {
    // clear all cookies when the page loads
    deleteCookie("authToken");
    deleteCookie("userPermissions");
    deleteCookie("workspace_options");
    deleteCookie("current_workspace");
    deleteCookie("userRole");
    deleteCookie("userDetail");
    deleteCookie("browserNotification");
  }, []);

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
        backgroundImage: `url(${PatternImage})`,
        backgroundRepeat: "repeat",
        width: "100%",
        height: "100vh",
        backgroundSize: "16px",
        backgroundColor: "rgba(240,75,85,1)",
        overflowX: "auto",
      }}
    >
      <CssBaseline />
      {/* ---------- login section ------------- */}
      <Grid sx={{ backgroundColor: "rgba(240,75,85,1)" }} item xs={12} sm={8} md={5} component={Paper} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* ---------- cyreen logo --------- */}
          <LogoImage src={WhiteLogo} alt="Logo" width="80" />
          <Box height={10} />
          {/* ---------- login screen text --------- */}
          <Typography component="h1" variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
            CAP Explorer
          </Typography>
          <Typography component="h1" variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
            Access your platform
          </Typography>
          <Box height={10} />
          {/* -------- login inputs ---------- */}
          <Box component="div" sx={{ mt: 1 }}>
            <Form onSubmit={Authenticate}>
              {/* ------ username ------ */}
              <WhiteInputField
                fieldRequired={false}
                elemId="email"
                labelName="Username or E-Mail"
                fieldName="email"
                defaultVal=""
                onChangeCall={(e) => {
                  // e.preventDefault();
                  setLoginData({ ...loginData, username: e.target.value.trim() });
                }}
                tabIndex={1}
              />
              {/* ------- password ----- */}
              <WhiteInputPasswordField
                fieldRequired={false}
                elemId="password"
                labelName="Password"
                fieldName="password"
                defaultVal=""
                onChangeCall={(e) => {
                  // e.preventDefault();
                  setLoginData({ ...loginData, password: e.target.value });
                }}
                tabIndex={2}
              />
              <Grid container alignItems="center" justifyContent="space-between">
                {/* ------ login button ----- */}
                <Grid item xs>
                  <LogInButton label={"Login"} onSubmit={Authenticate} icon={<Login />} />
                </Grid>
                {/* ------- forgot password link ----- */}
                <Grid item xs>
                  <Link
                    href="/forgot-password"
                    variant="body2"
                    sx={{ color: "#fff", fontWeight: "bold", textDecoration: "none" }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              {/* <input type="submit" value="Login" /> */}
            </Form>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
      {/* -------- pattern on right side of the login section ----------- */}
      {/* <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${PatternImage})`,
          backgroundRepeat: "repeat",
          width: "50%",
          height: "100vh",
          backgroundColor: "rgba(240,75,85,1)",
          backgroundPosition: "0 0",
          backgroundSize: "16px",
          alignSelf: "flex-end",
          marginTop: "-32px",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      /> */}
      {typeof currentStateData.show_workspace_list !== "undefined" && currentStateData.show_workspace_list === true && (
        <WorkSpace open={currentStateData.show_workspace_list} onClose={() => {}} size={"xs"} usedFor={"add"} />
      )}
    </Grid>
  );
}
